/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient($input: UpdatePatientInput!) {
    updatePatient(input: $input) {
      id
      email
      countryId
      firstName
      lastName
      dateOfBirth
      gender
      sleepTestType
      startTherapyGroup
      userEnteredAhi
      phoneNumber
      aboutMeProgress
      myEquipmentProgress
      myHealthProgress
      overallProgress
      achievementsPushEnabled
      coachingPushEnabled
      coachingSmsEnabled
      coachingEmailEnabled
      cleaningPushEnabled
      cleaningSmsEnabled
      cleaningEmailEnabled
      smartCoachingEnabled
      stepCountSharingEnabled
      healthSharingEnabled
      healthSharingLastPromptedVersion
      shareDetailsWithProviderOptIn
      analyticsMode
      analyticsId
      pushToken
      timezoneId
      furiganaFamilyName
      furiganaGivenName
      marketingOptIn
      achievements {
        id
        name
        description
        imageUrl
        earnedDate
      }
      journeys
      firstTherapySessionDate
      lifetimeUsageHours
      processHealthDataOptIn
    }
  }
`;
export const addFgDevice = /* GraphQL */ `
  mutation AddFgDevice($input: AddFgDeviceInput!) {
    addFgDevice(input: $input) {
      serialNumber
      deviceType
      deviceSeries
      deviceFamily
      lastSleepDataReportTime
      localizedName
      imagePath
      imageUrl
      fgDeviceManufacturerName
      fgDevicePatientId
      deviceSubtype
    }
  }
`;
export const addFlowGenerator = /* GraphQL */ `
  mutation AddFlowGenerator($input: AddFlowGeneratorInput!) {
    addFlowGenerator(input: $input) {
      serialNumber
      deviceType
      deviceSeries
      deviceFamily
      lastSleepDataReportTime
      localizedName
      imagePath
      imageUrl
      fgDeviceManufacturerName
      fgDevicePatientId
      deviceSubtype
    }
  }
`;
export const addFlowGeneratorV2 = /* GraphQL */ `
  mutation AddFlowGeneratorV2($input: AddFlowGeneratorInputV2!) {
    addFlowGeneratorV2(input: $input) {
      serialNumber
      deviceType
      deviceSeries
      deviceFamily
      lastSleepDataReportTime
      localizedName
      imagePath
      imageUrl
      fgDeviceManufacturerName
      fgDevicePatientId
      deviceSubtype
    }
  }
`;
export const addMask = /* GraphQL */ `
  mutation AddMask($input: AddMaskInput!) {
    addMask(input: $input) {
      maskManufacturerName
      maskCode
      maskType
      localizedName
      imagePath
      imageUrl
      maskPatientId
    }
  }
`;
export const removeFgDevice = /* GraphQL */ `
  mutation RemoveFgDevice($input: RemoveFgDeviceInput!) {
    removeFgDevice(input: $input) {
      serialNumber
      deviceType
      deviceSeries
      deviceFamily
      lastSleepDataReportTime
      localizedName
      imagePath
      imageUrl
      fgDeviceManufacturerName
      fgDevicePatientId
      deviceSubtype
    }
  }
`;
export const removeMask = /* GraphQL */ `
  mutation RemoveMask($input: RemoveMaskInput!) {
    removeMask(input: $input) {
      maskManufacturerName
      maskCode
      maskType
      localizedName
      imagePath
      imageUrl
      maskPatientId
    }
  }
`;
export const clearNotifications = /* GraphQL */ `
  mutation ClearNotifications($input: [ClearNotificationInput!]!) {
    clearNotifications(input: $input) {
      id
      action
      title
      description
      positiveButtonTitle
      negativeButtonTitle
      url
      negativeActionId
      negativeActionContent {
        title
        description
        positiveButtonTitle
        negativeButtonTitle
      }
      achievement {
        id
        name
        description
        imageUrl
        earnedDate
      }
    }
  }
`;
export const submitCCIData = /* GraphQL */ `
  mutation SubmitCCIData($input: CCIDataInput) {
    submitCCIData(input: $input)
  }
`;
export const addHealthMetric = /* GraphQL */ `
  mutation AddHealthMetric($input: HealthMetricDataInput!) {
    addHealthMetric(input: $input) {
      date
      metricValue
      metricType
    }
  }
`;
export const addHealthMetricSamples = /* GraphQL */ `
  mutation AddHealthMetricSamples($input: HealthMetricSampleInput!) {
    addHealthMetricSamples(input: $input) {
      ... on SleepMetricSample {
        startTime
        endTime
        sampleId
        stage
        shouldCountAsInBed
      }
    }
  }
`;
export const dismissCard = /* GraphQL */ `
  mutation DismissCard($input: DismissCardInput!) {
    dismissCard(input: $input)
  }
`;
export const deleteHealthSamples = /* GraphQL */ `
  mutation DeleteHealthSamples($input: DeleteHealthSamplesInput!) {
    deleteHealthSamples(input: $input)
  }
`;
export const submitMaskAnswers = /* GraphQL */ `
  mutation SubmitMaskAnswers($input: MaskQuestionsInput!) {
    submitMaskAnswers(input: $input)
  }
`;
