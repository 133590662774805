import { Component, OnInit } from '@angular/core';
import { PatientService } from 'src/app/services/patient/patient.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AboutMeDetails } from 'src/app/modules/my-account/components/my-account/about-me/types/about-me.type';
import { HealthProfile } from 'src/app/modules/my-account/components/my-account/my-health-profile/types/health-profile.type';
import { NotificationPreferences } from 'src/app/modules/my-account/components/my-account/my-notifications/types/notification-preferences.type';
import { AnalyticsMode } from 'src/graphql/types/api.types';
import { PatientWrapper } from 'src/app/services/patient/types/patient-wrapper.type';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base/component/base-component';
import { MyAccountDataService } from '../../services/my-account-data.service';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';
import { MetadataService } from 'src/app/services/metadata/metadata.service';
import { CountryService } from 'src/app/services/country/country.service';

@Component({
  selector: 'mpp-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent extends BaseComponent implements OnInit {
  public patient: PatientWrapper;
  public hasPatientData = false;
  public privacySettings = {
    // allowIdentifiedAnalytics is a boolean referring to the state of the My Account page's Allow Analytics checkbox. If true, checked. If false, unchecked.
    allowIdentifiedAnalytics: false,
    shareDetailsWithProviderOptIn: false,
    marketingOptIn: false,
    allowPersonalizedCoaching: false
  };
  public healthProfile: HealthProfile;
  public aboutMeDetails: AboutMeDetails;
  public notiificationPreferences: NotificationPreferences;
  public isJapan: boolean = false;
  public showSmartCoachingOptOut = false;

  constructor(
    private patientService: PatientService,
    private spinner: SpinnerService,
    private myAccountDataService: MyAccountDataService,
    private metadataService: MetadataService,
    private countryService: CountryService
  ) {
    super();
  }

  ngOnInit(): void {
    const spinner = this.spinner.show();
    forkJoin([this.metadataService.getMetadataFromApi(), this.patientService.getPatient()]).pipe(takeUntil(this.unsubscribe$)).subscribe((res) =>
      {
        let metadata = res[0];
        let errors = res[1][1];
        let patient = res[1][0];

        spinner.hide();
    this.showSmartCoachingOptOut = metadata.countries.find(country => country.code === this.metadataService.getSelectedCountryId())?.isSmartCoachingSupported;
    if (errors) {
      this.hasPatientData = false;
    } else {
      this.patient = patient;
      if(['JP'].includes(this.countryService.getSelectedCountryId())) {
        this.isJapan = true;
      } else {
        this.isJapan = false;
      }
      this.privacySettings = {
        allowIdentifiedAnalytics: environment.isEU ? this.patient.patient.analyticsMode === AnalyticsMode.anonymous :
                                  this.patient.patient.countryId === 'US' ? this.patient.patient.analyticsMode === AnalyticsMode.identified : null,
        shareDetailsWithProviderOptIn: !!this.patient.patient.shareDetailsWithProviderOptIn,
        marketingOptIn: this.patient.patient.marketingOptIn,
        allowPersonalizedCoaching: this.patient.patient.smartCoachingEnabled
      };
      this.healthProfile = {
        sleepTestType: this.patient.patient.sleepTestType,
        startTherapyGroup: this.patient.patient.startTherapyGroup,
        userEnteredAhi: this.patient.patient.userEnteredAhi,
      };
      this.myAccountDataService.healthProfileData.next({ ...this.healthProfile });
      this.aboutMeDetails = {
        firstName: this.patient.patient.firstName,
        lastName: this.patient.patient.lastName,
        dateOfBirth: this.patient.patient.dateOfBirth.toDateString(),
        gender: this.patient.patient.gender,
        timezoneId: this.patient.patient.timezoneId,
        furiganaGivenName: this.patient.patient.furiganaGivenName,
        furiganaFamilyName: this.patient.patient.furiganaFamilyName,
      };
      this.hasPatientData = true;
      this.notiificationPreferences = {
        cleaningEmailEnabled: patient.patient.cleaningEmailEnabled,
        coachingEmailEnabled: patient.patient.coachingEmailEnabled,
      };
    }
  }); 
  }
}
