 
<section class="o-section">
  <div class="o-wrapper">
    <div class="o-layout">
      <div class="o-layout__item [ u-10/12@desktop u-push-1/12@desktop ]">
        <form mppFocusOnInvalidControl [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
          <mpp-add-health-profile [healthProfileForm]="registrationForm.get('patientHealthProfile')"></mpp-add-health-profile>
        <!-- #region Patient Legal Consents -->
        <div #consents formGroupName="patientLegalConsents" *ngIf="!isEU">
          <div class="c-form-field">
            <div class="c-form-field__input c-input--validation">

              <label class="c-form-field__label">{{"REGISTRATION-FORM.KeepMeInformed" | translate}}</label>

              <div class="o-inline-group-tiny o-inline-group--top" *ngIf="allowShareInformation">
                <div class="c-input c-input--checkbox is-consent">
                  <input id="shareInformation" formControlName="shareDetailsWithProviderOptIn" type="checkbox" />
                  <label
                    for="shareInformation">
                    <span>
                      {{"REGISTRATION-FORM.ShareMyInfoAgreement_1" | translate}}
                    </span>
                    <span>
                      {{"REGISTRATION-FORM.ShareMyInfoAgreement_2" | translate}}
                    </span>
                  </label>
                </div>
              </div>

              <div class="o-inline-group-tiny o-inline-group--top">
                <div class="c-input c-input--checkbox is-consent">
                  <input id="marketingOptInCheckBox" formControlName="keepMeInformed" type="checkbox" /><label
                    for="marketingOptInCheckBox">
                    {{"REGISTRATION-FORM.KeepMeInformedOfNews" | translate}}
                  </label>
                </div>
              </div>
            </div>
          </div>

          
        </div>
        <!-- #endregion Patient Legal Consents -->
        <mpp-global-legal-consents
        *ngIf="isEU"
        [form]="registrationForm.get('patientLegalConsents')"
        [formSubmitted]="formSubmitted"></mpp-global-legal-consents>

        <div class="c-form-field c-form-field--buttons c-form-field--buttons-wizard">
        <div class="c-form-field--buttons-wizard__next">
          <button class="c-btn c-btn--primary" type="submit">
            {{"PTA.PtaStepperDone" | translate}}
          </button>
        </div>
        <div class="c-form-field--buttons-wizard__back">
          <button class="c-btn c-btn--tertiary" (click)="onCancel()">
            {{"REGISTRATION-FORM.Cancel" | translate}}
          </button>
        </div>
        </div>
        </form>
        </div>
    </div>
  </div>
</section>