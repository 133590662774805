import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mpp-global-legal-consents',
  templateUrl: './global-legal-consents.component.html',
  styleUrls: ['./global-legal-consents.component.scss'],
})

export class GlobalLegalConsentsComponent {
  @Input() form: UntypedFormGroup;
  @Input() formSubmitted: boolean;
  @Input() requireAnalyticsMode: boolean;
  @Input() marketingConsentDefault: boolean;
  @Input() requireProcessHealthDataOptIn: boolean;

  public isEU: boolean = environment.isEU;

}
