import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { onError } from '@apollo/client/link/error';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { Router } from '@angular/router';
import { Alert } from 'src/app/modules/alert/components/model/alert.model';
import { ErrorCodes, ErrorTypes } from 'src/app/services/apollo-setup/types/graphql-exception.enum';
import { HttpLink } from 'apollo-angular/http';
import { environment } from 'src/environments/environment';
import { HeadersService } from '../showtime-api/headers.service';
import { AlertService } from '../../modules/alert/components/services/alert.service';
import { GraphQLError } from '../apollo/graphql-payload.type';
import { MetadataService } from '../metadata/metadata.service';

@Injectable({
  providedIn: 'root',
})
export class ApolloSetupService {
  constructor(
    private apollo: Apollo,
    private headersService: HeadersService,
    private messageService: AlertService,
    private router: Router,
    private httpLink: HttpLink,
    private metadata: MetadataService
  ) {}

  setupApolloClient(): void {
    const rmdHeadersMiddleware = new ApolloLink((operation, forward) => {
      const headers = this.headersService.getRmdHeadersForApollo();
      operation.setContext({
        headers,
      });
      return forward(operation);
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        this.handleGraphQlErrors((graphQLErrors as unknown) as GraphQLError[]);
      }
      if (networkError) {
        this.showErrorMessage();
      }
    });

    const apolloLinks = ApolloLink.from([
      errorLink,
      rmdHeadersMiddleware,
      this.httpLink.create({ uri: this.metadata.instanceInfo.appSyncUrl }),
    ]);

    this.apollo.create({
      link: apolloLinks,
      cache: new InMemoryCache({
        typePolicies: {
          PatientWrapper: { keyFields: ['patient', ['id']] },
        },
      }),
    });

    this.apollo.client.defaultOptions = {
      mutate: {
        errorPolicy: 'all',
      },
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
    };
  }

  showErrorMessage(): void {
    const errorMessage = 'ERRORS.ApplicationError.ApplicationErrorOccured';
    const errorOptions: Partial<Alert> = {
      links: [
        {
          title: 'ERRORS.ApplicationError.MyAirSupport',
          callback: () => {
            this.router.navigate(['/support']);
          },
          textAfterLink: 'ERRORS.ApplicationError.ProblemContinues',
        },
      ],
    };
    this.messageService.info(errorMessage, errorOptions);
  }

  handleGraphQlErrors(gqlErrors: GraphQLError[]): void {
    gqlErrors.forEach((gqlError) => {
      const errorType = gqlError.errorInfo?.errorType;
      switch (errorType) {
        case ErrorTypes.unauthorized:
        case ErrorTypes.forbidden:
          this.router.navigate(['/']);
          break;
        case ErrorTypes.badRequest:
          this.handleBadRequestErrors(gqlError);
          break;
        default:
          this.logError(gqlError.message);
          this.showErrorMessage();
      }
    });
  }

  handleBadRequestErrors(gqlError: GraphQLError): void {
    this.logGraphQlError(gqlError);
    if (gqlError.errorInfo.errorCode === ErrorCodes.policyNotAccepted) {
      this.router.navigate(['/accept-policies']);
    }
  }

  logGraphQlError(gqlError: GraphQLError): void {
    if (!environment.production) {
      // eslint-disable-next-line no-console
      console.error(
        `GraphQL ${gqlError.errorInfo.errorType} ERROR: ${gqlError.errorInfo.errorCode}`,
        gqlError,
      );
    }
  }

  logError(message: string): void {
    if (!environment.production) {
      // eslint-disable-next-line no-console
      console.error(message);
    }
  }
}
