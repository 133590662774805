/* eslint-disable max-classes-per-file */
import { SleepTestId } from 'src/app/modules/registration/data/sleep-test.type';
import { StartTherapyGroupId } from 'src/app/modules/registration/data/start-therapy-group.type';
import { Statistics } from 'src/app/modules/registration/services/models/statistics.model';
// implementation postponed due to MWEB-714
// import { BaselineSleepinessValues } from 'src/graphql/types/api.types';

export class HealthProfile {
  constructor(
    public statistics: Statistics[],
    sleepTestType?: SleepTestId,
    startTherapyGroup?: StartTherapyGroupId,
    // implementation postponed due to MWEB-714
    // baselineSleepiness?: BaselineSleepinessValues,
  ) {
    if (sleepTestType) {
      this.sleepTestType = sleepTestType;
    }
    if (startTherapyGroup) {
      this.startTherapyGroup = startTherapyGroup;
    }
    // implementation postponed due to MWEB-714
    // if (baselineSleepiness) {
    //   this.baselineSleepiness = baselineSleepiness;
    // }
  }
  sleepTestType?: SleepTestId;
  startTherapyGroup?: StartTherapyGroupId;
  // implementation postponed due to MWEB-714
  // baselineSleepiness?: BaselineSleepinessValues;
}
