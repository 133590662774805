import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import {
  SleepTestId,
  sleepTestTranslated,
} from 'src/app/modules/registration/data/sleep-test.type';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { TimeZoneData } from 'src/app/modules/registration/data/time-zone.type';
import { RegistrationValidators } from 'src/app/modules/registration/validators/registration.validators';
import { CountryService } from 'src/app/services/country/country.service';
import { UserRegistrationService } from 'src/app/modules/registration/services/user-registration.service';
import { PatientInfoWithConsent } from 'src/app/modules/registration/services/models/patient-info.model';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { environment } from 'src/environments/environment';
import { BaseRestPatientInfoWithProfile, RestPatientInfoWithConsents } from '../services/models/patient-rest.converter';
import { HealthProfile } from '../services/models/health-profile.model';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { CountryId } from 'src/app/types/country.type';
import { Router } from '@angular/router';
import { AuthService } from '@auth/auth.service';

@Component({
  selector: 'mpp-registration-form',
  templateUrl: './consents.component.html',
  styleUrls: ['./consents.component.scss'],
})
export class ConsentComponent implements OnInit, OnDestroy {
  @ViewChild('consents') consents: ElementRef;
  selectedCountryId: CountryId;
  readonly nameMaxLength = 50;
  readonly emailMaxLength = 100;
  // the value of unknown test type
  public notKnownSleepTestTypeKey = (Object.keys(sleepTestTranslated) as Array<SleepTestId>).find(
    (key) => sleepTestTranslated[key] === '',
  );

  private unsubscribe$ = new Subject<void>();
  // Reactive form for patient input
  public registrationForm: UntypedFormGroup;
  // checks if user has tried to submit the form
  // if submited form was invalid - focus on invalid fields
  public formSubmitted = false;
  public isValidFormSubmitted = false;
  public isSubmitSuccessful = false;
  public isJapan = false;
  public minimumAge = 13;
  public isEU = environment.isEU;
  public supportedCountryIds: string[] = [];
  public countryNames: string[] = [];
  public countries;
  public timeZoneData: readonly TimeZoneData[];

  constructor(
    private countryService: CountryService,
    private userRegistrationService: UserRegistrationService,
    private spinnerService: SpinnerService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private authService: AuthService
  ) {}

  async ngOnInit(): Promise<void> {

    this.minimumAge = this.isEU ? 16 : 13;

    this.registrationForm = new UntypedFormGroup({ 
      // Group that describes patient health profile: ahi events,
      // when patient started therapy, where patient has his sleep test
      patientHealthProfile: new UntypedFormGroup({
        AHIevents: new UntypedFormControl(null, [
          // eslint-disable-next-line
          Validators.pattern(/^((0|([1-9](\d)?)|1\d{2})(\.\d)?|2[0]{2}(.0)?)$/),
          Validators.maxLength(5),
          Validators.max(200),
          Validators.min(0),
          RegistrationValidators.restrictFirstNumberAsZero,
        ]),
        startTherapyGroup: new UntypedFormControl(null),
        // if no sleep test type was selected the test type is unknown
        sleepTestType: new UntypedFormControl(this.notKnownSleepTestTypeKey),
        // implementation postponed due to MWEB-714
        // baselineSleepiness: new FormControl(null),
      }),
      // Group that describes patient acceptance of usage of his information
      patientLegalConsents: new UntypedFormGroup({
        analyticsMode: new UntypedFormControl(
          !this.isEU,
        ),
        shareDetailsWithProviderOptIn: new UntypedFormControl(false),
        keepMeInformed: new UntypedFormControl(false),
      }),
    });

    this.analyticsService.logScreenViewWeb('screen_therapy_pap_consents');
  }

  getPatientInRestFormat(): BaseRestPatientInfoWithProfile {
    return new RestPatientInfoWithConsents(
      this.registrationForm,
      this.countryService
    );
  }

  public onSubmit(): void {
      this.formSubmitted = true;
      const patientInRestFormat = this.getPatientInRestFormat();
      if (this.registrationForm.valid) {
        this.isValidFormSubmitted = true;
        this.registerPatientAccount(
          patientInRestFormat.getPatientInfoForConsents(),
          patientInRestFormat.getHealthProfile(),
        );
      }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public trackBy(index: number, el: HTMLElement): string {
    return el.id;
  }


  /**
   * Checks if current form control is required
   * @param formControlName name of control which is checked
   */
  public isFormControlRequired(formControlName: string): boolean {
    return (
      (this.registrationForm.get(formControlName).errors?.required &&
        (this.registrationForm.get(formControlName).touched ||
          this.registrationForm.get(formControlName).dirty)) ||
      (this.registrationForm.get(formControlName).invalid && this.formSubmitted)
    );
  }

  private registerPatientAccount(patientInfo: PatientInfoWithConsent, healthProfile: HealthProfile): void {
    const spinner = this.spinnerService.show();
    this.userRegistrationService
      .registerPatientWithConsent(patientInfo, healthProfile)
      .subscribe((response) => {
        spinner.hide();
        
        if (response[1]) {
          this.router.navigate(['/dashboard']);
        }
      });
  }


  async onCancel(): Promise<void> {
    const spinner = this.spinnerService.show();
    this.analyticsService.logLoggedOutEvent();
    await this.authService.logout();
    spinner.hide();
  }
}