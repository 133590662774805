import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as mutations from 'src/graphql/mutations';
import {
  RemoveFgDeviceInput,
  AddMaskInput,
  RemoveMaskInput,
  UpdatePatientInput,
  GetPatientWrapperQuery,
  AddFlowGeneratorInputV2,
  FgSeries,
  FgFamily,
  Notification,
  PatientJourney,
} from 'src/graphql/types/api.types';
import { HttpClient } from '@angular/common/http';
import { AlertService } from 'src/app/modules/alert/components/services/alert.service';
import { Router } from '@angular/router';
import { FgDevice, Mask } from 'src/app/services/patient/types/device.type';
import { GraphQLError } from 'src/app/services/apollo/graphql-payload.type';
import { ApolloService } from 'src/app/services/apollo/apollo.service';
import { Patient } from 'src/app/services/patient/types/patient.type';
import { CoachingLibraryData } from 'src/app/services/patient/types/coaching-library.type';
import { NotificationPreferencesInput } from 'src/app/services/patient/types/notification-preferences-input';
import { User } from 'src/app/models/user.model';
import { CoachingHistoryTips } from './types/coaching-history.type';
import { DayJSService } from '../dayJS/dayJS.service';
import { PatientWrapper } from './types/patient-wrapper.type';

export class MaskWithNotification {
  patient: {
    id: string
  }
  masks: {
    maskManufacturerName: string;
    maskCode: string;
    maskType: string;
    localizedName: string;
    imagePath: string;
    maskPatientId: string;
  }[]
  notifications: Notification[]
}

export class DeviceWithNotification {
  patient: {
    id: string
    journeys: PatientJourney[]
  }
  fgDevices: {
    serialNumber: string,
    deviceSeries?: FgSeries,
    deviceFamily?: FgFamily,
    lastSleepDataReportTime?: string,
    localizedName: string,
    imagePath: string,
    fgDeviceManufacturerName: string,
    fgDevicePatientId: string,
  }[]
  notifications: Notification[]
}

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  public patient$: Subject<User> = new Subject<User>();
  constructor(
    protected http: HttpClient,
    protected messageService: AlertService,
    protected router: Router,
    private apolloService: ApolloService,
    private dayJsService: DayJSService,
  ) {}

  getPatient(): Observable<[PatientWrapper, GraphQLError]> {
    // implementation postponed due to MWEB-714
    // baselineSleepiness
    const query = /* GraphQL */ `
      query getPatientWrapper {
        getPatientWrapper {
          patient {
            id
            shareDetailsWithProviderOptIn
            email
            countryId
            firstName
            lastName
            dateOfBirth
            gender
            sleepTestType
            startTherapyGroup
            coachingEmailEnabled
            coachingPushEnabled
            cleaningEmailEnabled
            cleaningPushEnabled
            analyticsMode
            analyticsId
            userEnteredAhi
            timezoneId
            furiganaFamilyName
            furiganaGivenName
            marketingOptIn,
            smartCoachingEnabled
          }
          fgDevices {
            serialNumber
            deviceSeries
            lastSleepDataReportTime
            localizedName
            imagePath
            fgDeviceManufacturerName
            fgDevicePatientId
          }
          masks {
            maskManufacturerName
            maskCode
            maskType
            localizedName
            imagePath
            maskPatientId
          }
          sleepLibrary {
            title
            thumbnail
            description
            videoOrHtmlUrl
            contentId
            language
            sleepLibraryId
            section
            type
            sleepLibraryPatientId
          }
          coachingHistory {
            items {
              date
              sleepLibraryId
            }
          }
        }
      }
    `;
    return this.apolloService
      .query<GetPatientWrapperQuery>({
        query: gql(query),
      })
      .pipe(
        map(([patientResult, error]) => {
          if (error) {
            return [null, error];
          }
          const dateJs = this.dayJsService.dayJS(
            patientResult.getPatientWrapper.patient.dateOfBirth,
          );
          const dateOfBirth = patientResult.getPatientWrapper.patient.dateOfBirth
            ? new Date(dateJs.year(), dateJs.month(), dateJs.date())
            : null;
          return [
            {
              ...patientResult.getPatientWrapper,
              patient: { ...patientResult.getPatientWrapper.patient, dateOfBirth },
            },
            null,
          ];
        }),
      );
  }

  getPatientMask(): Observable<[PatientWrapper, GraphQLError]> {
    // Removed un-necessary data queried and created new GraphQL call
    const query = /* GraphQL */ `
      query getPatientWrapper {
        getPatientWrapper {
          patient {
            id
          }
          fgDevices {
            serialNumber
            deviceSeries
            lastSleepDataReportTime
            localizedName
            imagePath
            fgDeviceManufacturerName
            fgDevicePatientId
          }
          masks {
            maskManufacturerName
            maskCode
            maskType
            localizedName
            imagePath
            maskPatientId
          }
        }
      }
    `;
    return this.apolloService
      .query<GetPatientWrapperQuery>({
        query: gql(query),
      })
      .pipe(
        map(([patientResult, error]) => {
          if (error) {
            return [null, error];
          }
          const dateJs = this.dayJsService.dayJS(
            patientResult.getPatientWrapper.patient.dateOfBirth,
          );
          const dateOfBirth = patientResult.getPatientWrapper.patient.dateOfBirth
            ? new Date(dateJs.year(), dateJs.month(), dateJs.date())
            : null;
          return [
            {
              ...patientResult.getPatientWrapper,
              patient: { ...patientResult.getPatientWrapper.patient, dateOfBirth },
            },
            null,
          ];
        }),
      );
  }

  getCoachingLibrary(): Observable<[CoachingLibraryData, GraphQLError]> {
    const query = /* GraphQL */ `
      query GetPatientLibrary {
        getPatientWrapper {
          patient {
            id
          }
          fgDevices {
            localizedName
            deviceFamily
            deviceSeries
            imagePath
          }
          masks {
            localizedName
            maskCode
            imagePath
          }
          sleepLibrary {
            title
            thumbnail
            description
            videoOrHtmlUrl
            contentId
            language
            sleepLibraryId
            section
            type
            sleepLibraryPatientId
          }
        }
      }
    `;
    return this.apolloService
      .query<GetPatientWrapperQuery>({
        query: gql(query),
      })
      .pipe(
        map(([libraryResult, error]) => {
          if (error) {
            return [null, error];
          }
          return [
            {
              sleepLibrary: libraryResult.getPatientWrapper.sleepLibrary,
              fgDevices: libraryResult.getPatientWrapper.fgDevices,
              masks: libraryResult.getPatientWrapper.masks,
            },
            null,
          ];
        }),
      );
  }

  getCoachingHistory(): Observable<[CoachingHistoryTips, GraphQLError]> {
    const query = /* GraphQL */ `
      query GetPatient {
        getPatientWrapper {
          patient {
            id
          }
          sleepLibrary {
            title
            thumbnail
            description
            videoOrHtmlUrl
            contentId
            language
            sleepLibraryId
            section
            type
            sleepLibraryPatientId
          }
          coachingHistory {
            items {
              date
              sleepLibraryId
            }
          }
        }
      }
    `;
    return this.apolloService
      .query<GetPatientWrapperQuery>({
        query: gql(query),
      })
      .pipe(
        map(([historyResult, error]) => {
          if (error) {
            return [null, error];
          }
          return [
            {
              coachingHistory: historyResult.getPatientWrapper.coachingHistory,
              sleepLibrary: historyResult.getPatientWrapper.sleepLibrary,
            },
            null,
          ];
        }),
      );
  }

  getDevices(): Observable<[DeviceWithNotification, GraphQLError]> {
    const query = /* GraphQL */ `
      query GetPatientDevice {
        getPatientWrapper {
          patient {
            id
            journeys
          }
          fgDevices {
            serialNumber
            deviceSeries
            deviceFamily
            lastSleepDataReportTime
            localizedName
            imagePath
            fgDeviceManufacturerName
            fgDevicePatientId
          }
          notifications {
            id
            action
            title
            description
            positiveButtonTitle
            negativeButtonTitle
            url
            negativeActionId
          }
        }
      }
    `;
    return this.apolloService
      .query<GetPatientWrapperQuery>({
        query: gql(query),
      })
      .pipe(
        map(([devicesResult, error]) => {
          if (error) {
            return [null, error];
          }
          return [devicesResult.getPatientWrapper, null];
        }),
      );
  }

  getMask(): Observable<[MaskWithNotification, GraphQLError]> {
    const query = /* GraphQL */ `
      query GetPatientMask {
        getPatientWrapper {
          patient {
            id
          }
          masks {
            maskManufacturerName
            maskCode
            maskType
            localizedName
            imagePath
            maskPatientId
          }
          notifications {
            id
            action
            title
            description
            positiveButtonTitle
            negativeButtonTitle
            url
            negativeActionId
          }
        }
      }
    `;
    return this.apolloService
      .query<GetPatientWrapperQuery>({
        query: gql(query),
      })
      .pipe(
        map(([devicesResult, error]) => {
          if (error) {
            return [null, error];
          }
          return [devicesResult.getPatientWrapper, null];
        }),
      );
  }

  removeDevice(serialNumber: RemoveFgDeviceInput): Observable<[GraphQLError, FgDevice]> {
    return this.apolloService.mutate<FgDevice>({
      mutation: gql(mutations.removeFgDevice),
      variables: {
        input: serialNumber,
      },
    });
  }

  addDevice(device: AddFlowGeneratorInputV2): Observable<[GraphQLError, FgDevice]> {
    return this.apolloService.mutate<FgDevice>({
      mutation: gql(mutations.addFlowGeneratorV2),
      variables: {
        input: device,
      },
      update: (cache) => {
        cache.reset();
      },
    });
  }

  addMask(mask: AddMaskInput): Observable<[GraphQLError, Mask]> {
    return this.apolloService.mutate<Mask>({
      mutation: gql(mutations.addMask),
      variables: { input: mask },
      update: (cache) => {
        cache.reset();
      },
    });
  }

  removeMask(removeMaskInput: RemoveMaskInput): Observable<[GraphQLError, Mask]> {
    return this.apolloService.mutate<Mask>({
      mutation: gql(mutations.removeMask),
      variables: {
        input: removeMaskInput,
      },
    });
  }

  setPrivacySettings(settings: Partial<UpdatePatientInput>): Observable<[GraphQLError, Patient]> {
    return this.apolloService.mutate<Patient>({
      mutation: gql(/* GraphQL */ `
        mutation updatePatient($input: UpdatePatientInput!) {
          updatePatient(input: $input) {
            id
            analyticsMode
            shareDetailsWithProviderOptIn
            analyticsId
            marketingOptIn,
            smartCoachingEnabled
          }
        }
      `),
      variables: { input: settings },
    });
  }

  setNotificationPreferences(
    preferences: NotificationPreferencesInput,
  ): Observable<[GraphQLError, Patient]> {
    return this.apolloService.mutate<Patient>({
      mutation: gql(/* GraphQL */ `
        mutation UpdatePatient($input: UpdatePatientInput!) {
          updatePatient(input: $input) {
            id
            coachingEmailEnabled
            cleaningEmailEnabled
          }
        }
      `),
      variables: {
        input: preferences,
      },
    });
  }

  updateAboutMeDetails(
    aboutMeDetails: Partial<UpdatePatientInput>,
  ): Observable<[GraphQLError, Patient]> {
    return this.apolloService.mutate<Patient>({
      mutation: gql(/* GraphQL */ `
        mutation UpdatePatient($input: UpdatePatientInput!) {
          updatePatient(input: $input) {
            id
            firstName
            lastName
            furiganaGivenName
            furiganaFamilyName
            dateOfBirth
            gender
            timezoneId
          }
        }
      `),
      variables: {
        input: aboutMeDetails,
      },
      update: (cache, result) => {
        type PatientResult = {
          firstName: string;
          lastName: string;
          gender: string;
        };
        const patient = (result.data as Record<string, unknown>).updatePatient as PatientResult;
        this.patient$.next({
          FirstName: patient.firstName,
          LastName: patient.lastName,
          FullName: `${patient.firstName} ${patient.lastName}`,
          Gender: patient.gender,
        } as User);
      },
    });
  }

  updateHealthProfile(
    healthProfile: Partial<UpdatePatientInput>,
  ): Observable<[GraphQLError, Patient]> {
    // implementation postponed due to MWEB-714
    // baselineSleepiness: ${healthProfile.baselineSleepiness}
    return this.apolloService.mutate<Patient>({
      mutation: gql(/* GraphQL */ `
        mutation {
          updatePatient(input: { sleepTestType: ${healthProfile.sleepTestType}
                                startTherapyGroup: ${healthProfile.startTherapyGroup}
                                userEnteredAhi: ${healthProfile.userEnteredAhi}}) {
            id
            sleepTestType
            startTherapyGroup
            userEnteredAhi
          }
        }
      `),
    });
  }

  getAnalyticsInfo(): Observable<[PatientWrapper, GraphQLError]> {
    const query = /* GraphQL */ `
      query GetPatientAnalytics {
        getPatientWrapper {
          patient {
            id
            dateOfBirth
            gender
            analyticsMode
            analyticsId
            shareDetailsWithProviderOptIn
          }
        }
      }
    `;
    return this.apolloService
      .query<GetPatientWrapperQuery>({
        query: gql(query),
      })
      .pipe(
        map(([patientResult, error]) => {
          if (error) {
            return [null, error];
          }
          const dateJs = this.dayJsService.dayJS(
            patientResult.getPatientWrapper.patient.dateOfBirth,
          );
          const dateOfBirth = patientResult.getPatientWrapper.patient.dateOfBirth
            ? new Date(dateJs.year(), dateJs.month(), dateJs.date())
            : null;
          return [
            {
              ...patientResult.getPatientWrapper,
              patient: { ...patientResult.getPatientWrapper.patient, dateOfBirth },
            },
            null,
          ];
        }),
      );
  }
}
