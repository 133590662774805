import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, HostListener, ViewChild, ElementRef} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cultureLanguageIds, languages } from 'src/app/types/language.type';
import { LanguageService } from 'src/app/services/language/language.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CountryService } from 'src/app/services/country/country.service';
import { AuthService } from '@auth/auth.service';
import { WINDOW_OBJECT } from 'src/app/services/window/window.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../base/component/base-component';
import { LanguageId } from 'src/app/types/language.type';
import { combineLatest } from 'rxjs';
import { LocalStorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'mpp-root',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent extends BaseComponent implements OnInit {
  layout = 'default';
  private oneTrustCookieBannerIsLoaded = false;
  public showOneTrustFooter: boolean;
  public defaultLanguageSelect: LanguageId;
  public chatbotAllowed: boolean = false;
  public chatbotLoaded: boolean = false;
  public chatbotVisible: boolean = false;
  public chatbotTextLoaded: boolean = false;
  public chatbotTextVisible: boolean = false;
  public chatbotWelcomeBack: boolean = false;
  private chatbotUrls: any = undefined;
  private notAppended: boolean = true;

  @ViewChild('dawnAIButton') dawnAIButton: ElementRef;

  constructor(
    public translate: TranslateService,
    private languageService: LanguageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private countryService: CountryService,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW_OBJECT) public window: Window,
    private renderer: Renderer2,
    public localStorageService: LocalStorageService
  ) {
    super();
    translate.addLangs([...cultureLanguageIds]);
  }

  async ngOnInit(): Promise<void> {
    this.loadDawnAIChatbot();

    // Show blue footer for AMR, hide it for EU
    if(!environment.isEU) {
      this.showOneTrustFooter = true;
      this.defaultLanguageSelect = 'EnglishUs';
    } else {
      this.showOneTrustFooter = false;
      this.defaultLanguageSelect = 'EnglishUk';
    }
    this.languageService.checkAndSetLanguageId(this.defaultLanguageSelect);

    this.countryService.loadCountries()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.countryService.checkAndSetCountryId();
        }
      );

    this.languageService.selectedLanguageId$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((language) => {
        this.translate.use(languages[language]);
        this.setOneTrustLanguage();
      });

    this.authService.authenticatedUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (user) {
        this.analyticsService.checkAndSetAnalytics();
        this.languageService.checkAndSetCountryAvailableLanguage(
          this.countryService.getSelectedCountry(),
        );
      }
    });

    this.countryService.selectedCountryId$.pipe(takeUntil(this.unsubscribe$)).subscribe((_) => {
      if (!this.countryService.getSelectedCountry()) return;

      this.appendOneTrustCookieBanner();

      // if AMR, run function to determine whether footer hides (non-CPRA) or shows (CPRA)
      if (!environment.isEU) {
        this.hideShowOneTrustFooterButton();
      }

    });

    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.layout = this.activatedRoute.firstChild.firstChild.snapshot.data.layout || 'default';
      }
    });
    this.authService.getUserInfo();
    this.authService.setOnRenewedHandler();
  }

  @HostListener('document:click', ['$event'])
  public handleClick(event: Event): void {
    if (event.target instanceof HTMLAnchorElement) {
      const element = event.target as HTMLAnchorElement;
      if (element.className.includes('myair-routerlink')) {
        event.preventDefault();
        const route = element?.getAttribute('href');
        if (route) {
          this.router.navigate([`/${route}`]);
        }
      }
    }
  }


  loadDawnAIChatbot(): void {
    if (environment.dawnURL) {
      // Fetch the chatbot script URLs only once
      if (!this.chatbotUrls) {
        fetch(environment.dawnURL)
          .then(response => response.json())
          .then(urls => {
            // Store the fetched URLs so we don't fetch them again
            this.chatbotUrls = urls;
  
            // Now we combine country selection and authentication status
            this.initializeChatbotListener();
          })
          .catch(error => {
            console.error('Failed to load chatbot script URLs', error);
          });
      } else {
        // If URLs are already fetched, directly initialize the listener
        this.initializeChatbotListener();
      }
    }
  }
  
  initializeChatbotListener(): void {
    combineLatest([
      this.countryService.selectedCountryId$,
      this.authService.isAuthenticated$
    ]).subscribe(([countryId, isAuthenticated]) => {
      const currentlyAllowed = (countryId === 'AU' && isAuthenticated);
  
      if (!this.chatbotAllowed && currentlyAllowed && this.notAppended) {
        // Prevemt numerous appendings of the script
        this.notAppended = false;
        // Load the chatbot script async and enable the chatbot button once it is loaded
        const script = document.createElement('script');
        
        // Set the correct URL based on the environment
        script.src = this.chatbotUrls[environment.dawnEnv];
        
        script.onload = () => {
          this.chatbotLoaded = true;
          this.chatbotAllowed = true;
  
          // After a rendering cycle, add a click listener to make Firebase logs
          setTimeout(() => {
            if (this.chatbotAllowed && this.chatbotLoaded) {
              this.renderer.listen(this.dawnAIButton.nativeElement, 'click', () => {
                this.analyticsService.logDawnAILaunched(this.countryService.getSelectedCountryId());
              });
              
              // Delay visibility of Chatbot text 
              setTimeout(() => { 
                this.chatbotTextLoaded = true; 
                this.chatbotTextVisible = true;
                this.chatbotWelcomeBack = !this.localStorageService.getItem(this.localStorageService.dawnWelcomeBack);
              }, 3000);
            }
          }, 0);
        };
        // Initialize the script
        document.body.appendChild(script);
      }
      this.chatbotVisible = currentlyAllowed;
    });
  }
  

  // Dynamically construct and append the OneTrust cookie banner on an AMR vs EU basis
  appendOneTrustCookieBanner(): void {
    if (this.oneTrustCookieBannerIsLoaded) return;

    let oneTrustEnv: string;
    let oneTrustSrc: string;

    // if AMR
    if(!environment.isEU) {
      oneTrustEnv = environment.oneTrustIdAMR;
      oneTrustSrc = `https://cdn.cookielaw.org/consent/${environment.oneTrustIdAMR}/OtAutoBlock.js`;
    // else if EU  
    } else {
      oneTrustEnv = environment.oneTrustIdEU;
      oneTrustSrc = `https://cdn.cookielaw.org/consent/${environment.oneTrustIdEU}/OtAutoBlock.js`;
    }

    const bannerScript = this.renderer.createElement('script');
    this.renderer.setAttribute(bannerScript, 'src', oneTrustSrc);
    this.renderer.setAttribute(bannerScript, 'type', 'text/javascript');
    this.renderer.appendChild(this.document.body, bannerScript);

    const bannerScript2 = this.renderer.createElement('script');
    this.renderer.setAttribute(bannerScript2, 'src','https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
    this.renderer.setAttribute(bannerScript2, 'type', 'text/javascript');
    this.renderer.setAttribute(bannerScript2, 'charset', 'UTF-8');
    this.renderer.setAttribute(bannerScript2, 'data-domain-script', oneTrustEnv);
    this.renderer.appendChild(this.document.body, bannerScript2);

    if (!environment.isEU) {
      const bannerScript3 = this.renderer.createElement('script');
      this.renderer.setAttribute(bannerScript3, 'type', 'text/javscript');
      bannerScript3.innerHTML="function OptanonWrapper() { }";
      this.renderer.appendChild(this.document.body, bannerScript3);
    }

    this.oneTrustCookieBannerIsLoaded = true;
    
    setTimeout(() => {
      this.setOneTrustLanguage()
    }, 1000);

  }

  setOneTrustLanguage() {
    if (this.window['OneTrust']) {
        this.window['OneTrust'].changeLanguage(
          this.languageService.getCurrent2LettersLanguage().slice(0, 2),
        );
    }
  }

  hideShowOneTrustFooterButton() {
    // Wait for OneTrust footer to render
    this.waitForElm('#ot-sdk-btn').then((elm: HTMLElement) => {
      let that = this;
      const target = document.querySelector('#ot-sdk-btn');
      const observer = new MutationObserver( mutate );
      const config = { characterData: false, attributes: false, childList: true, subtree: false };

      // Wait for OneTrust footer's .textContent to be loaded into footer
      function mutate(mutations) {
          mutations.forEach(function(mutation) {
          // If OneTrust footer contains only whitespace string (non-CPRA), hide footer. Otherwise, show footer (CPRA)
          if (elm.textContent) {
            if (!elm.textContent.trim()) {
              that.showOneTrustFooter = false;
            } else {
              that.showOneTrustFooter = true;
            }
          }
        });
      }
    
      observer.observe(target, config);
    });
    
    
    // Wait for OneTrust banner to render (if OneTrust banner has not been previously dismissed)
    this.waitForElm('#onetrust-button-group').then((elm: HTMLElement) => {
      if (elm.childElementCount > 1) {
        this.showOneTrustFooter = true;
      } else {
        this.showOneTrustFooter = false;
      }
    });
  }

  // Function which waits for asynchronous OneTrust cookie banner/footer HTML to render
  waitForElm(selector) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
  }

}