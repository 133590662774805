import { UntypedFormGroup } from '@angular/forms';
import { DayJSService } from 'src/app/services/dayJS/dayJS.service';
import { StatisticEvents } from 'src/app/modules/registration/data/statistic-events.enum';
import { PatientInfoV2, PatientInfoWithConsent } from 'src/app/modules/registration/services/models/patient-info.model';
import { HealthProfile } from 'src/app/modules/registration/services/models/health-profile.model';
import { Statistics } from 'src/app/modules/registration/services/models/statistics.model';
import { LanguageService } from 'src/app/services/language/language.service';
import { AnalyticsMode } from 'src/graphql/types/api.types';
import { environment } from 'src/environments/environment';
import { CountryService } from 'src/app/services/country/country.service';
import { UserRegistrationService } from '../user-registration.service';


export interface BaseRestPatientInfoV2 {
  getPatientInfoV2(): PatientInfoV2
}

// @Karl Brown this will need adjusting to the Patient Registration V2 payload as part of MWEB-1556
export interface BaseRestPatientInfoWithProfile {
  getPatientInfoForConsents(): PatientInfoWithConsent;
  getHealthProfile(): HealthProfile;
}

export class RestPatientInfoWithConsents implements BaseRestPatientInfoWithProfile {
  private registrationForm: UntypedFormGroup;

  constructor(
    registrationForm: UntypedFormGroup,
    private countryService: CountryService
  ) {
    this.registrationForm = registrationForm;
  }

  public getPatientInfoForConsents(): PatientInfoWithConsent {
    // analyticsFormValue toggles when the registration form analytics checkbox is checked. Default is false for Europe, true for all other countries. 
    const analyticsFormValue: boolean = this.registrationForm.get('patientLegalConsents.analyticsMode').value;
    const allowAnonymous = environment.isEU ? AnalyticsMode.untracked : AnalyticsMode.anonymous;
    const analyticsModeByRegion = this.countryService.getSelectedCountryId() === 'US' ? AnalyticsMode.identified : AnalyticsMode.anonymous;
    const analyticsMode = analyticsFormValue ? analyticsModeByRegion : allowAnonymous;

       return new PatientInfoWithConsent(
    this.registrationForm.get('patientLegalConsents.keepMeInformed').value,
    this.registrationForm.get('patientLegalConsents.shareDetailsWithProviderOptIn').value,
    analyticsMode,
  );
}
  
  public getHealthProfile(): HealthProfile {
   return new HealthProfile(
      this.getStatistics(),
      this.registrationForm.get('patientHealthProfile.sleepTestType').value,
      this.registrationForm.get('patientHealthProfile.startTherapyGroup').value,
      // implementation postponed due to MWEB-714
      // this.registrationForm.get('patientHealthProfile.baselineSleepiness').value,
    );
  }

  private getStatistics(): Statistics[] {
    return [
      new Statistics(
        StatisticEvents.BaselineApneaHypopneaIndex,
        this.registrationForm.get('patientHealthProfile.AHIevents').value?.toString(), // value of AHI events
      ),
    ];
  }
}

export class RestPatientInfo implements BaseRestPatientInfoV2 {
  private registrationForm: UntypedFormGroup;

  constructor(
    registrationForm: UntypedFormGroup,
    private dayJSService: DayJSService,
    private languageService: LanguageService,
    private userRegistrationService: UserRegistrationService
  ) {
    this.registrationForm = registrationForm;
  }


  //Utility method to check if a value is populated
  private isPopulated(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    if (typeof value === 'string') {
      return value.trim() !== '';
    }
    if (typeof value === 'number') {
      return !isNaN(value);
    }
    return true;
  }

  public getPatientInfoV2(): PatientInfoV2 {
    const form = this.registrationForm;
    // Helper function for non-numeric fields.
    // If a value is not populated, set it as undefined so it doesn't post to database
    const getValue = (path: string) => {
      const value = form.get(path).value;
      return this.isPopulated(value) ? value : undefined;
    };

    // Helper function for numeric fields
    // If a value is not populated, set it as undefined so it doesn't post to database
    const getNumericValue = (path: string) => {
      const value = getValue(path);
      return value !== undefined ? Number(value) : undefined;
    };

    const patientCountry = this.registrationForm.get('patientPersonalInfo.country').value;
    // analyticsFormValue toggles when the registration form analytics checkbox is checked. Default is false for Europe, true for all other countries.
    const analyticsFormValue: boolean = this.registrationForm.get('patientLegalConsents.analyticsMode').value;
    const allowAnonymous = environment.isEU ? AnalyticsMode.untracked : AnalyticsMode.anonymous;
    const analyticsModeByRegion = patientCountry === 'US' ? AnalyticsMode.identified : AnalyticsMode.anonymous;
    const analyticsMode = analyticsFormValue ? analyticsModeByRegion : allowAnonymous;

    return new PatientInfoV2 (
      !!this.registrationForm.get('patientLegalConsents.termsOfUseConsent').value,
      this.getFormattedBirthDay(),
      (this.registrationForm.get('patientPersonalInfo.signUpGroup.email').value as string).trim(),
      patientCountry,
      this.languageService.getCurrent2LettersLanguage(),
      (this.registrationForm.get('patientPersonalInfo.lastName').value as string).trim(),
      (this.registrationForm.get('patientPersonalInfo.firstName').value as string).trim(),
      this.registrationForm.get('patientPersonalInfo.gender').value,
      this.registrationForm.get('patientPersonalInfo.timeZone').value,
      !!this.registrationForm.get('patientLegalConsents.keepMeInformed').value,
      !!this.registrationForm.get('patientLegalConsents.shareDetailsWithProviderOptIn').value,
      // set optional parameters to undefined if empty
      getValue('patientPersonalInfo.furiganaFamilyName')?.trim(),
      getValue('patientPersonalInfo.furiganaGivenName')?.trim(),
      analyticsMode,
      !!getValue('patientLegalConsents.processHealthDataOptIn'),
      this.isPopulated(this.userRegistrationService.serialNumber) ? this.userRegistrationService.serialNumber.toString() : undefined,
      this.isPopulated(this.userRegistrationService.deviceNumber) ? this.userRegistrationService.deviceNumber.toString() : undefined,
      // no getValue on sleepTestType because empty sleepTestType sends "notKnown"
      this.registrationForm.get('patientHealthProfile.sleepTestType').value,
      getValue('patientHealthProfile.startTherapyGroup'),
      getNumericValue('patientHealthProfile.AHIevents')
    );
  }

  private getFormattedBirthDay(): string {
    const birthYear = this.registrationForm.get('patientPersonalInfo.birthday.year').value;
    const birthMonth = this.registrationForm.get('patientPersonalInfo.birthday.month').value;
    const birthDay = this.registrationForm.get('patientPersonalInfo.birthday.day').value;
    const date = `${this.dayJSService
      .dayJS(birthYear, 'YYYY', true)
      .format('YYYY')}-${this.dayJSService
      .dayJS(birthMonth, 'MMM')
      .format('MM')}-${birthDay.toString().padStart(2, '0')}T00:00:00`;
    return date;
  }
  
  public getHealthProfile(): HealthProfile {
   return new HealthProfile(
      this.getStatistics(),
      this.registrationForm.get('patientHealthProfile.sleepTestType').value,
      this.registrationForm.get('patientHealthProfile.startTherapyGroup').value,
      // implementation postponed due to MWEB-714
      // this.registrationForm.get('patientHealthProfile.baselineSleepiness').value,
    );
  }

  private getStatistics(): Statistics[] {
    return [
      new Statistics(
        StatisticEvents.BaselineApneaHypopneaIndex,
        this.registrationForm.get('patientHealthProfile.AHIevents').value?.toString(), // value of AHI events
      ),
    ];
  }
}